import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${match.url}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${match.url}/user-management`} component={lazy(() => import(`./UserManagement`))} />
        <Route path={`${match.url}/ads-management`} component={lazy(() => import(`./AdsManagement`))} />
        <Route path={`${match.url}/reports`} component={lazy(() => import(`./Report`))} />
        <Route path={`${match.url}/masters`} component={lazy(() => import(`./masters`))} />
        <Route path={`${match.url}/enquiries`} component={lazy(() => import(`./enquiries`))} />
        <Route path={`${match.url}/Video`} component={lazy(() => import(`./Video`))} />
        <Route path={`${match.url}/ebook`} component={lazy(() => import(`./ebook`))} />
        <Route path={`${match.url}/elearning`} component={lazy(() => import(`./elearning`))} />
        <Route path={`${match.url}/appbanner`} component={lazy(() => import(`./appbanner`))} />
        <Route path={`${match.url}/cmswebsite`} component={lazy(() => import(`./cmswebsite`))} />
        <Route path={`${match.url}/promocodes`} component={lazy(() => import(`./promocodes`))} />
        <Route path={`${match.url}/examcenter`} component={lazy(() => import(`./examcenter`))} />
        <Route path={`${match.url}/mediagroup`} component={lazy(() => import(`./mediagroup`))} />
        <Route path={`${match.url}/mediaitem`} component={lazy(() => import(`./mediaitem`))} />
        <Route path={`${match.url}/notificationcampaign`} component={lazy(() => import(`./notificationcampaign`))} />
        <Route path={`${match.url}/testpapers`} component={lazy(() => import(`./testpapers`))} />
        <Route path={`${match.url}/questions`} component={lazy(() => import(`./questions`))} />
        <Route path={`${match.url}/setting`} component={lazy(() => import(`./setting`))} />
        <Redirect from={`${match.url}`} to={`${match.url}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
