import {
  CodeSandboxOutlined,
  SoundOutlined,
  UserOutlined,
  ReadOutlined,
  TeamOutlined,
  BankOutlined,
  GlobalOutlined,
  WalletOutlined,
  ProfileOutlined,
  CommentOutlined,
  AndroidOutlined,
  SettingOutlined,
  ApartmentOutlined,
  QuestionCircleOutlined,
  FileDoneOutlined,
  LineChartOutlined,
  DashboardOutlined,
  AntDesignOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
  VideoCameraOutlined,
  FileProtectOutlined,
  AppstoreAddOutlined,
  UsergroupAddOutlined,
  NotificationOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'home',
  path: '/app/dashboards',
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: '/app/dashboards/default',
      title: 'sidenav.home.dashboard',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'Video',
      path: '/app/Video',
      title: 'sidenav.home.Video',
      icon: VideoCameraOutlined,
      breadcrumb: true,
      submenu: []
    },

  ]
}]

const appBannersNavTree = [{
  key: 'appbanner',
  path: '/app/appbanner',
  title: 'sidenav.home.appbanner',
  icon: AndroidOutlined,
  breadcrumb: true,
  submenu: []
}]
const notificationCampaignNavTree = [{
  key: 'notification_campaign',
  path: '/app/notificationcampaign',
  title: 'sidenav.home.notification_campaign',
  icon: NotificationOutlined,
  breadcrumb: true,
  submenu: []
},]

const userManagementNavTree = [{
  key: 'user-management',
  path: '/app/user-management',
  title: 'sidenav.user-management',
  icon: AntDesignOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'author',
      path: '/app/user-management/author',
      title: 'sidenav.user-management.author',
      icon: TeamOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'customers',
      path: '/app/user-management/customers',
      title: 'sidenav.user-management.customers',
      icon: TeamOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'customer-list',
          path: '/app/user-management/customers/list',
          title: 'sidenav.user-management.customers.list',
          icon: ProfileOutlined,
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'businessuser',
      path: '/app/user-management/business-user',
      title: 'sidenav.user-management.businessuser',
      icon: TeamOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'businessuser-list',
          path: '/app/user-management/business-user/list',
          title: 'sidenav.user-management.businessuser.list',
          icon: ProfileOutlined,
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'user-group',
      path: '/app/user-management/user-group',
      title: 'sidenav.user-management.user-group',
      icon: UsergroupAddOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'user-group-list',
          path: '/app/user-management/user-group/list',
          title: 'sidenav.user-management.user-group.list',
          icon: ProfileOutlined,
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'promocodes',
      path: '/app/promocodes',
      title: 'sidenav.home.promocodes',
      icon: AppstoreAddOutlined,
      breadcrumb: true,
      submenu: [{
        key: 'promocodes-customers',
        path: '/app/promocodes/customers',
        title: 'sidenav.home.promocodes.customers',
        icon: UserOutlined,
        breadcrumb: true,
        submenu: []
      }]
    }
  ]
}]

const adsManagementNavTree = [{
  key: 'ads-management',
  path: '/app/ads-management',
  title: 'sidenav.ads-management',
  icon: SoundOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'advertisement',
      path: '/app/ads-management/advertisement',
      title: 'sidenav.ads-management.advertisement',
      icon: SoundOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'package-ads',
      path: '/app/ads-management/package-ads',
      title: 'sidenav.ads-management.package-ads',
      icon: SoundOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]
}]
const elearningManagementNavTrees = [
  {
    key: 'elearning',
    path: '/app/elearning',
    title: 'sidenav.home.elearning',
    icon: VideoCameraOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'elearning-category',
        path: '/app/elearning/category',
        title: 'sidenav.home.elearning.category',
        icon: AppstoreAddOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'elearning-languages',
        path: '/app/elearning/languages',
        title: 'sidenav.home.elearning.languages',
        icon: AppstoreAddOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'elearning-package',
        path: '/app/elearning/package',
        title: 'sidenav.home.elearning.package',
        icon: CodeSandboxOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'Ebook',
        path: '/app/ebook',
        title: 'sidenav.home.ebook',
        icon: ReadOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'mediagroup',
        path: '/app/mediagroup',
        title: 'sidenav.home.mediagroup',
        icon: YoutubeOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'mediaitem',
        path: '/app/mediaitem',
        title: 'sidenav.home.mediaitem',
        icon: YoutubeOutlined,
        breadcrumb: true,
        submenu: []
      },
    ]
  }
]

const examCenterNavTrees = [
  {
    key: 'examcenter',
    path: '/app/examcenter',
    title: 'sidenav.home.examcenter',
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'examcenter-categary',
        path: '/app/examcenter/categary',
        title: 'sidenav.home.examcenter.categary',
        icon: ApartmentOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'examcenter-testseries',
        path: '/app/examcenter/testseries',
        title: 'sidenav.home.examcenter.testseries',
        icon: InfoCircleOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'test_paper',
        path: '/app/testpapers',
        title: 'sidenav.home.test_paper',
        icon: AntDesignOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'question',
        path: '/app/questions',
        title: 'sidenav.home.question',
        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: []
      }
    ]
  }
]

const mastersNavTree = [{
  key: 'masters',
  path: '/app/masters',
  title: 'sidenav.home.masters',
  icon: AntDesignOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'masters-university',
      path: '/app/masters/university',
      title: 'sidenav.home.masters.university',
      icon: BankOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'masters-stream',
      path: '/app/masters/stream',
      title: 'sidenav.home.masters.stream',
      icon: FileDoneOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'masters-topic',
      path: '/app/masters/topic',
      title: 'sidenav.home.masters.topic',
      icon: ReadOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
  ]
}]

const reportsNavTree = [{
  key: 'reports',
  path: '/app/reports',
  title: 'sidenav.reports',
  icon: AntDesignOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'sales-order',
      path: '/app/reports/sales-order',
      title: 'sidenav.reports.sales-order',
      icon: FileProtectOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'invoice',
      path: '/app/reports/invoice',
      title: 'sidenav.reports.invoice',
      icon: FileProtectOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'reports-productanalytics',
      path: '/app/reports/productanalytics',
      title: 'sidenav.reports.productanalytics',
      icon: LineChartOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'reports-walletwithdrawlog',
      path: '/app/reports/walletwithdrawlog',
      title: 'sidenav.reports.walletwithdrawlog',
      icon: WalletOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'sales-advertisers',
      path: '/app/reports/sales-advertisers',
      title: 'sidenav.reports.sales-advertisers',
      icon: FileProtectOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
    {
      key: 'sales-test-series',
      path: '/app/reports/sales-test-series',
      title: 'sidenav.reports.sales-test-series',
      icon: FileProtectOutlined,
      breadcrumb: true,
      submenu: [
      ]
    },
  ]
}]

const cmsWebsiteNavTree = [
  {
    key: 'cmswebsite',
    path: '/app/cmswebsite',
    title: 'sidenav.home.cmswebsite',
    icon: AppstoreAddOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'cmswebsite-webbanners',
        path: '/app/cmswebsite/webbanners',
        title: 'sidenav.home.cmswebsite.webbanners',
        icon: GlobalOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'cmswebsite-faq',
        path: '/app/cmswebsite/faq',
        title: 'sidenav.home.cmswebsite.faq',
        icon: CommentOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'cmswebsite-testimonials',
        path: '/app/cmswebsite/testimonials',
        title: 'sidenav.home.cmswebsite.testimonials',
        icon: CommentOutlined,
        breadcrumb: true,
        submenu: []
      },
    ]
  },
]

const enquiriesNavTree = [{
  key: 'enquiries',
  path: '/app/enquiries',
  title: 'sidenav.home.enquiries',
  icon: FileSearchOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'enquiries-ads',
      path: '/app/enquiries/ads',
      title: 'sidenav.home.enquiries.ads',
      icon: FileSearchOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'enquiries-businesslead',
      path: '/app/enquiries/businesslead',
      title: 'sidenav.home.enquiries.businesslead',
      icon: FileSearchOutlined,
      breadcrumb: true,
      submenu: []
    },
  ]
}]

const settingsNavTree = [
  {
    key: 'setting',
    path: '/app/setting',
    title: 'sidenav.home.setting',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: []
  },
]

const navigationConfig = [
  ...dashBoardNavTree,
  ...appBannersNavTree,
  ...adsManagementNavTree,
  ...userManagementNavTree,
  ...notificationCampaignNavTree,
  ...elearningManagementNavTrees,
  ...examCenterNavTrees,
  ...mastersNavTree,
  ...cmsWebsiteNavTree,
  ...reportsNavTree,
  ...enquiriesNavTree,
  ...settingsNavTree
]

export default navigationConfig;
