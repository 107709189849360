import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	USER_INFO,
	SIGNIN,
	SIGNOUT,
} from '../constants/Auth';

import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
} from "../actions/Auth";

import AuthService from 'services/AuthService'

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const response = yield call(AuthService.login, email, password);

			if (response.status === "success") {
				localStorage.setItem(AUTH_TOKEN, response.data.token);
				localStorage.setItem(USER_INFO, JSON.stringify(response.data.user_info));
				yield put(authenticated(response.data));
			} else {
				yield put(showAuthMessage(response.message));
			}
		} catch (err) {
			// if()
			yield put(showAuthMessage(err.response.data.message));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			const response = yield call(AuthService.logout);
			if (response.status === "success") {
				localStorage.removeItem(AUTH_TOKEN);
				localStorage.removeItem(USER_INFO);
				yield put(signOutSuccess());
			} else {
				yield put(showAuthMessage(response.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithFBEmail),
		fork(signOut),
	]);
}
