
const dev = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
	TINY_MCE_PLUGIN_URL: process.env.REACT_APP_TINY_MCE_PLUGIN_URL
};

const prod = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
	TINY_MCE_PLUGIN_URL: process.env.REACT_APP_TINY_MCE_PLUGIN_URL
};

const staging = {
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
	TINY_MCE_PLUGIN_URL: process.env.REACT_APP_TINY_MCE_PLUGIN_URL
};

const getEnv = () => {
	switch (process.env.REACT_APP_NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'staging':
			return staging
		default:
			break;
	}
}

export const env = getEnv()
