export const API_BASE_URL = 'https://your-api-url.com/'

// Notification
export const USER_NOTIFICATION_LIST = 'api/admin/getUserNotification';
export const USER_NOTIFICATION_VIEWED = 'api/admin/markNotificationAsRead';

// Author
export const SEARCH_AUTHORS = 'api/common/searchKeyword/author';
export const AUTHOR_LIST = 'api/admin/author/list';
export const AUTHOR_CREATE = 'api/admin/author/create';
export const AUTHOR_UPDATE = 'api/admin/author/update/';
export const AUTHOR_UPDATE_STATUS = 'api/common/author/';
export const AUTHOR_RESET_PASSWORD = 'api/admin/author/reset_password/';

// Setting
export const APP_INFO_LIST = 'api/admin/addUpdateAppInfo';
export const MOCK_INFO_MESSAGE = 'api/admin/getMockInfo';
export const APP_INFO_UPDATE_STATUS = 'api/admin/updateAppInfoStatus/';

// Elearning Package
export const ELEARNING_PACKAGE_EBOOK_ADD = 'api/admin/elearning/ebook/addEbook';
export const ELEARNING_PACKAGE_CREATE_EBOOK_AND_ASSOCIATE = 'api/admin/elearning/ebook/addEbookAndAssociate';
export const ELEARNING_PACKAGE_EBOOK_UPDATE = 'api/admin/elearning/ebook/updateEbook/';
export const ELEARNING_PACKAGE_EBOOK_REMOVE = 'api/admin/elearning/ebook/deleteEbook/';
export const ELEARNING_PACKAGE_EBOOK_LIST = 'api/admin/elearning/ebook/list';
export const ELEARNING_PACKAGE_ITEM_TYPE_LIST = 'api/common/elearning_package_item_type';
export const ASSIGNED_CATEGORY_LIST = 'api/admin/elearning/package/assigned_category_list/';
export const ASSIGNED_CATEGORY_ADD_UPDATE = 'api/admin/elearning/package/assigned_category/';
export const DELETE_ASSIGNED_CATEGORY = 'api/common/elearning_package_avail_category/';

// Ebook
export const EBOOK_LIST = 'api/admin/ebook/list';
export const EBOOK_CREATE = 'api/admin/ebook/create';
export const EBOOK_UPDATE = 'api/admin/ebook/update/';
export const EBOOK_REMOVE = 'api/admin/ebook/delete';
export const EBOOK_DETAIL = 'api/admin/ebook/detail/';
export const EBOOK_UPDATE_STATUS = 'api/admin/ebook/updateStatus';
export const EBOOK_TYPE_LIST = 'api/common/ebook_type';
export const EBOOK_MEDIA_GROUP_LIST = 'api/admin/ebook/mediaGroupList/';
export const ASSIGNED_ELEARNING_PACKAGES = 'api/admin/ebook/assignedElearningPackages/';
export const SEARCH_MEDIA_GROUPS = 'api/common/searchKeyword/ebook_media_group';
export const EBOOK_MEDIA_GROUP_ADD = 'api/admin/ebook/addMediaGroup';
export const EBOOK_MEDIA_GROUP_REMOVE = 'api/admin/ebook/deleteMediaGroup/';

// Ads Management
export const ADS_CAMPAIGN_COMMON = 'api/common/ad_campaign/';
export const ADS_CAMPAIGN_PUBLISH = 'api/admin/ads_campaign/publish_add_campaign';
export const ADS_CAMPAIGN_LIST = 'api/admin/ads_campaign/list';
export const ADS_CAMPAIGN_DETAIL = 'api/admin/ads_campaign/detail/';
export const ADS_CAMPAIGN_REJECT = 'api/admin/ads_campaign/reject_campaign/';
export const ADS_CAMPAIGN_TYPE_LIST = 'api/common/ad_campaign_type';
export const ADS_CAMPAIGN_STATUS_LIST = 'api/common/ad_campaign_status';
export const ADS_CAMPAIGN_PACKAGE_COMMON = 'api/common/ad_campaign_package/';
export const USER_BUSINESS_PROFILE_SEARCH = 'api/common/searchKeyword/user_business_profile';