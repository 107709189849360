import {
	AUTH_TOKEN,
	USER_INFO,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SHOW_LOADING,
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	user: JSON.parse(localStorage.getItem(USER_INFO)),
}

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				user: action.user_info
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				user: null,
				redirect: '/',
				loading: false
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		default:
			return state;
	}
}

export default auth