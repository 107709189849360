import axios from 'axios'
import history from '../history'

import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const INTERNAL_SERVER_ERROR_ROUTE = '/auth/error-2'
const TOKEN_PAYLOAD_KEY = 'x-auth-token'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

	// if (!jwtToken) {
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }

	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	console.log('response', response);
	return response.data
}, (error) => {
	let notificationParam = {
		message: ''
	}
	console.log("error.response.data", error.response);
	if (error && error.response) {
		// Remove token and redirect 
		if ((error.response.status === 401 && error.response.data.code === 'UNAUTHORIZED') || error.response.status === 403) {
			notificationParam.message = 'Authentication Fail'
			notificationParam.description = 'Please login again'
			localStorage.removeItem(AUTH_TOKEN)
			history.push(ENTRY_ROUTE)
			window.location.reload();
		}

		if (error.response.status === 404) {
			notificationParam.message = 'Not Found'
			notification.error(notificationParam)
		}

		if (error.response.status === 400) {
			notificationParam.message = error.response.data.message
			notification.error(notificationParam)

		}

		if (error.response.status === 500) {
			history.push(INTERNAL_SERVER_ERROR_ROUTE)
			window.location.reload();
		}

		if (error.response.status === 508) {
			notificationParam.message = 'Time Out'
			notification.error(notificationParam)
		}
	}

	return Promise.reject(error);
});

export default service