import apis from 'auth/ApiInterceptor'

const AuthService = {
	login: async (email, password) => {
		return await apis({
			url: '/api/auth/admin/login',
			method: 'post',
			data: {
				"username": email,
				"password": password,
				"client": "MOBILE"
			},
			header: {
				'Content-Type': 'application/json'
			}
		})
	},
	logout: async () => {
		return await apis({
			url: '/api/auth/logout',
			method: 'get',
		})
	},
	changePassword: async (data) => {
		return await apis({
			url: '/api/auth/changePassword',
			method: 'post',
			data
		})
	}
}

export default AuthService