import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  SettingOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/app/setting"
  },
]

export const NavProfile = ({ onSignOut, user_profile }) => {
  console.log('user_profile', user_profile);
  const avatar = user_profile.thumb_profile_image_url
    ? <Avatar size={45} src={user_profile.thumb_profile_image_url} />
    : <Avatar
      style={{
        backgroundColor: '#063970',
      }}
      icon={<UserOutlined />}
    />
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {avatar}
          <div className="pl-3">
            <h4 className="mb-0">{user_profile.screen_name}</h4>
            <span className="text-muted">{user_profile.role_name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => onSignOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          {avatar}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onSignOut: () => dispatch(signOut())
  }
}


const mapStateToProps = ({ auth }) => {
  return {
    user_profile: auth.user
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
