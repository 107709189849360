import { SIDE_NAV_DARK } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'RajaEdutech';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const TINY_MCE_PLUGIN_URL = env.TINY_MCE_PLUGIN_URL


export const THEME_CONFIG = {
	"navType": "SIDE",
	"sideNavTheme": SIDE_NAV_DARK,
	"navCollapsed": false,
	"topNavColor": "#3e82f7",
	"headerNavColor": "#3e82f7",
	"locale": "en"
};
