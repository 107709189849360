import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Badge, List, Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'

import ApiService from '../../services/CommonService';
import { USER_NOTIFICATION_LIST, USER_NOTIFICATION_VIEWED } from '../../constants/ApiConstant';

const getNotificationBody = list => {
  return list.length > 0 ?
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="mr-3">
              <span className="text-gray-light">
                {item.notification_body.replace(/<[^>]+>/g, '')}
              </span>
            </div>
          </Flex>
        </List.Item>
      )}
    />
    :
    <div className="empty-notification">
      <p className="mt-3">You have viewed all notifications</p>
    </div>;
}

export const NavNotification = () => {

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([])

  useEffect(() => {
    fetchUserNotification();
  }, [])

  const fetchUserNotification = async () => {
    const response = await ApiService.get(USER_NOTIFICATION_LIST)
    setData(response.data);
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const clearHandler = async () => {
    const notification_ids = data.map(item => item.id);
    setData([]);
    ApiService.create(USER_NOTIFICATION_VIEWED, {
      notification_ids
    })
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button type="link" onClick={clearHandler} size="small">Clear </Button>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data)}
      </div>
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
